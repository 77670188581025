<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <div v-if="userDataInfo">
      <div
        style="font-size: 1em;"
        class="text-center bg-primary text-white p-50"
      >
        Hello
        <strong
          >{{ userDataInfo ? userDataInfo.name : "N/A" }}
          {{ userDataInfo ? userDataInfo.surname : "N/A" }} [{{
            userDataInfo ? userDataInfo.role : "N/A"
          }}],</strong
        >
        welcome to Portal.
        <h5 class="muted text-white mt-25">{{ dateNow }}</h5>
      </div>

      <div class="container mt-1" v-if="!userDataInfo.service_staff">
        <div class="text-center mt-25 d-flex justify-content-center">
          <treeselect
            class="mr-25"
            v-model="selectedYear"
            :multiple="false"
            :options="availableYears"
            @input="selectYear(selectedYear)"
            :clearable="false"
            style="width: 100px;"
          >
            <template #option="{ node }">
              {{ node.label }}
            </template>
          </treeselect>

          <treeselect
            class="mr-25"
            v-model="selectedWeek"
            :multiple="false"
            :options="dropdownWeeks"
            @input="selectWeek(selectedWeek)"
            :clearable="false"
            style="width: 250px;"
          >
            <div slot="value-label" slot-scope="{ node }">
              {{ node.raw.customLabel }}
            </div>
          </treeselect>

          <b-button @click="moveWeeks(-3)" variant="dark" class="mr-25"
            >&lt;&lt; Back</b-button
          >
          <b-button
            v-for="(week, index) in displayedWeeks"
            :key="index"
            style="width: 5%; height: 37px; font-size: 14px;"
            :variant="
              week.week_number === selectedWeek ? 'success' : 'secondary'
            "
            class="p-50 m-0 mr-25 flex-fill"
            @click="selectedWeek = week.week_number"
          >
            {{ week.week_number }}
          </b-button>
          <b-button @click="moveWeeks(+3)" variant="dark"
            >Next &gt;&gt;</b-button
          >
        </div>

        <div class="container" v-show="permissionListData.length > 0">
        <table class="table mt-3 text-center">
          <thead>
            <tr>
              <th>Department</th>
              <th>Staff</th>
              <th>Date</th>
              <th>Current</th>
              <th>Requested</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in permissionListData" :key="index">
              <td>{{ item.department.title }}</td>
              <td>
                {{ item.staff.name }} <small>{{ item.staff.surname }}</small>
              </td>
              <td>
                {{ item.date }} <span v-if="item.historical_change == true"> <br> <b-badge variant="warning">Historical Change</b-badge></span>
                <span v-else-if="item.historical_change == 'Location Change'"> <br> <b-badge variant="info">Location Change</b-badge></span>
              </td>
              <!-- Eski yeniyi ters gösteriyoruz. Onay işleminden sonra düzeltilecek -->
              <td>
                <span class="p-50">{{ item.new_task.title }}</span>
              </td>
              <td>
                <span class="p-50">{{ item.old_task.title }}</span>
              </td>
              <td>
                <button
                  v-show="$Can('approve_schedule_changes')"
                  class="btn btn-success btn-sm mr-25"
                  @click="approveItem(item)"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  v-show="$Can('reject_schedule_changes')"
                  class="btn btn-danger btn-sm"
                  @click="rejectItem(item, index)"
                >
                  <i class="fas fa-times"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="col-12 mb-2" style="border:1px solid #008bcc;">
          <div>
            <ul class="timeline">
              <li
                class="timeline-item"
                v-for="(item, index) in timelineItems"
                :key="index"
              >
                <div class="timeline-badge">{{ index + 1 }}</div>
                <div class="timeline-panel">
                  <div class="timeline-heading">
                    <h4 class="timeline-title">{{ item.title }}</h4>
                  </div>
                  <div class="timeline-body">
                    {{ item.content }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-show="permissionListData.length == 0 && userDataInfo.role!='admin'">
        <hr />
        <p class="text-center mt-5 text-info" style="font-size: 2rem;">
          There are no change requests for this week.
        </p>
      </div>


        <table class="table text-center mt-1" v-if="reportData.length > 0">
          <thead>
            <tr>
              <th>
                Task Title<br />
                {{ selectedYear }} /  WEEK {{ selectedWeek }}
              </th>
              <th v-for="(day, index) in days" :key="index">
                {{ day }} <br />
                <small>{{ reportData[0].dates[index] }}</small>
              </th>
              <th>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(task, taskIndex) in reportData" :key="taskIndex">
              <td
                :style="{
                  backgroundColor: task.color.bgColor,
                  color: task.color.textColor,
                }"
              >
                {{ task.title }}
              </td>
              <td
                v-for="(count, dayIndex) in task.counts"
                :key="dayIndex"
                v-b-tooltip.html.bottom
                :title="
                  '<strong>AM: </strong>' +
                    count.AM +
                    '<br> <strong>PM: </strong>' +
                    count.PM
                "
              >
                {{ count.AM + count.PM }}
              </td>
              <td style="font-weight: bold;" class="bg-light-info">
                {{ task.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div  v-else>
          <user-schedule :available-sites="this.userDataInfo.available_sites" :user-id="userDataInfo.id" :is-homepage="true"></user-schedule>



      </div>

    
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import homeStoreModule from "./homeStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import { VBPopover } from "bootstrap-vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserSchedule from "./user/user-preview/UserSchedule.vue";


export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    Treeselect,
    ToastificationContent,
    UserSchedule
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = "home";
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME))
      store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME))
        store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.checkUser();
    this.getScheduleReport();
    this.fetchWeeks();
  },

  computed: {
    displayedWeeks() {
      const selectedWeekIndex = this.weeks.findIndex(
        (week) => week.week_number === this.selectedWeek
      );
      let start = Math.max(0, selectedWeekIndex - 2);
      const end = Math.min(this.weeks.length, start + 5);
      if (end === this.weeks.length) {
        start = Math.max(0, end - 5);
      }
      return this.weeks.slice(start, end);
    },
  },

  data() {
    const dateNow = moment(new Date()).format("DD/MM/YYYY");
    const now = moment(new Date());
    const currentWeek = now.isoWeek();

    return {
      availableYears: [
        { id: 2024, label: 2024 },
        { id: 2025, label: 2025 },
        { id: 2026, label: 2026 },
        { id: 2027, label: 2027 },
        { id: 2028, label: 2028 },
        { id: 2029, label: 2029 },
        { id: 2030, label: 2030 },
      ],
      selectedYear: new Date().getFullYear(),
      dropdownWeeks: [],
      selectedWeek: currentWeek,
      days: ["monday", "tuesday", "wednesday", "thursday", "friday"],

      weeks: [],
      dateNow,
      formShow: false,
      userDataInfo: null,

      permissionListData: [],
      reportData: [],
      timelineItems: [
        // { title: 'Title 1', content: 'Content 1' },
        // { title: 'Title 2', content: 'Content 2' },
        // { title: 'Title 3', content: 'Content 3' },
      ],
    };
  },
  directives: {
    "b-popover": VBPopover,
  },

  methods: {
    kFormatter,

    checkUser() {
      this.formShow = true;

      this.userId = parseInt(this.userData.id);

      store
        .dispatch("home/fetchUser", { id: this.userId })
        .then((response) => {
          this.userDataInfo = response.data;
         

          if (response.data.status != "active") {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
              useJwt.jwtConfig.storageRefreshTokenKeyName
            );
            localStorage.removeItem("userData");
            router.push({ name: "auth-login" });
          } else {
            this.getPermissionListReqChange();
          }
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    getPermissionListReqChange() {
      this.formShow = true;
      store
        .dispatch("home/getPermissionListReqChange", {
          deparmentId: this.userId,
          year: this.selectedYear,
          week: this.selectedWeek,
        })
        .then((res) => {
          this.permissionListData = res.data;
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    getScheduleReport() {
      this.formShow = true;
      store
        .dispatch("home/getScheduleReport", {
          deparmentId: this.userId,
          year: this.selectedYear,
          week: this.selectedWeek,
        })
        .then((res) => {
          this.reportData = res.data;
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    fetchWeeks() {
      this.formShow = true;
      store
        .dispatch("home/fetchWeeks", { year: this.selectedYear })
        .then((res) => {
          this.weeks = res.data.weeks;
          this.dropdownWeeks = this.weeks.map((week) => ({
            id: week.week_number,
            label: `Week ${week.week_number}  | ${this.formattedDate(
              week.start_date
            )} - ${this.formattedDate(week.end_date)}`,
            customLabel: `Week ${week.week_number} `,
          }));
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    selectWeek(val) {
      this.selectedWeek = val;
      this.getScheduleReport();
      this.getPermissionListReqChange();
    },

    moveWeeks(offset) {
      const newSelectedWeek = this.selectedWeek + offset;
      if (newSelectedWeek > 0 && newSelectedWeek <= this.weeks.length) {
        this.selectedWeek = newSelectedWeek;
      }

      if (this.weeks.length != this.selectedWeek && this.selectedWeek != 1) {
        const selectedWeekIndex = this.weeks.findIndex(
          (week) => week.week_number === this.selectedWeek
        );
        const start = Math.max(0, selectedWeekIndex - 2);
        const end = Math.min(this.weeks.length, start + 5);

        return this.weeks.slice(start, end);
      }
    },

    formattedDate(startDate) {
      return moment(startDate).format("DD/MM/YYYY");
    },

    // approveItem(item) {
    //   this.$swal({
    //     title: 'Are you sure?',
    //     text: 'Do you want to approve this schedule item?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Yes, approve it!',
    //     cancelButtonText: 'No, cancel!',
    //     reverseButtons: true,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.loading = true;
    //       store
    //         .dispatch('home/approveScheduleItem', item)
    //         .then((res) => {
    //           this.loading = false;
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Successful',
    //               text: '✔️ Schedule has been changed as new task.',
    //               icon: 'ThumbsUpIcon',
    //               variant: 'success',
    //             },
    //           });
    //           this.getPermissionListReqChange();
    //           this.getScheduleReport();
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //           this.loading = false;
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'An error occurred',
    //               text: 'Please try again later or contact support.',
    //               icon: 'AlertTriangleIcon',
    //               variant: 'danger',
    //             },
    //           });
    //         });
    //     }
    //   });
    // },

    approveItem(item) {
      this.$bvModal
        .msgBoxConfirm("Do you want to approve this schedule item?", {
          title: "Are you sure?",
          size: "md",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Yes, approve it!",
          cancelTitle: "No, cancel!",
          centered: true,
          noCloseOnBackdrop: true,
        })
        .then((result) => {
          if (result) {
            this.loading = true;
            store
              .dispatch("home/approveScheduleItem", item)
              .then((res) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful",
                    text: "✔️ Schedule has been changed as new task.",
                    icon: "ThumbsUpIcon",
                    variant: "success",
                  },
                });
                this.getPermissionListReqChange();
                this.getScheduleReport();
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "An error occurred",
                    text: "Please try again later or contact support.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },

    rejectItem(item) {
      this.$bvModal
        .msgBoxConfirm("Do you want to reject this schedule item?", {
          title: "Are you sure?",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes, reject it!",
          cancelTitle: "No, cancel!",
          centered: true,
          noCloseOnBackdrop: true,
        })
        .then((result) => {
          if (result) {
            this.loading = true;
            store
              .dispatch("home/rejectScheduleItem", item)
              .then((res) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successful",
                    text: "✔️ Schedule has been rejected.",
                    icon: "ThumbsUpIcon",
                    variant: "success",
                  },
                });
                this.getPermissionListReqChange();
                this.getScheduleReport();
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "An error occurred",
                    text: "Please try again later or contact support.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
}

.timeline-badge {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-panel {
  width: calc(100% - 50px);
  padding-left: 65px;
}

.timeline-title {
  margin-top: 0;
  margin-bottom: 5px;
}

.timeline-body {
  font-size: 16px;
}
.table th,
.table td {
  vertical-align: middle;
}
</style>
