<template>
  <b-overlay
    :show="formShow"
    rounded="sm"
    no-fade
    v-if="$Can('view_schedule_tab') || userId == userData.id"
  >
    <b-card class="m-0 p-0">
      <b-row class="mb-25">
        <b-col cols="12" md="6" class="mb-1 mb-md-0">
          <div class="d-flex align-items-center">
            <span class="mr-2 font-weight-bold">Today's Date:</span>
            <span>{{ dateNow }}</span>
          </div>
          <div class="d-flex align-items-center">
            <span class="mr-2 font-weight-bold">Display Month:</span>
            <span>{{ getMonthDate }}</span>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-md-0"
        >
          <b-button variant="primary" @click="downloadUserPDF" class="mr-1">
            Download PDF
          </b-button>

          <b-button
            variant="primary"
            @click="duplicate"
            class=""
            v-if="userData.role == 'supermanager' || userData.role == 'admin'"
          >
            Duplicate Week
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <div class="container text-center mt-1 d-flex justify-content-center">
      <treeselect
        class="mr-25"
        v-model="selectedYear"
        :multiple="false"
        :options="availableYears"
        @input="selectYear(selectedYear)"
        :clearable="false"
        style="width: 100px;"
      >
        <template #option="{ node }">
          {{ node.label }}
        </template>
      </treeselect>

      <treeselect
        class="mr-25"
        v-model="selectedMonth"
        :multiple="false"
        :options="availableMonths"
        @input="selectMonth(selectedMonth)"
        :clearable="false"
        style="width: 150px;"
      >
        <template #option="{ node }">
          {{ node.label }}
        </template>
      </treeselect>

      <treeselect
        class="mr-25"
        v-model="selectedWeeks"
        :multiple="true"
        :options="dropdownWeeks"
        @input="selectWeeks(selectedWeeks)"
        :limit="3"
        :clearable="false"
        style="width: 500px;"
      >
        <div slot="value-label" slot-scope="{ node }">
          {{ node.raw.customLabel }}
        </div>
      </treeselect>
    </div>

    <span v-if="weeks.length > 0">
      <div class="mt-1 weekTableCard" :style="isHomepage ? '' : { height: windowHeight + 'px' }">
        <div v-for="(week, weekIndex) in selectedWeeks" :key="week" class="mt-1">
          <h3>Week {{ week }} </h3>
          <table
            class="table table-bordered table-sm mb-2"
            data-filter-control="true"
            data-show-search-clear-button="true"
          >
            <thead style="position: sticky; top: 0; z-index: 1;">
              <tr class="text-center">
                <th class="m-0 p-0" style="width: 60px;">
                  <div style="position: relative;">
                    <button
                      style="color: white; border: none; cursor: pointer; width:100%; height: 50px; background-color:transparent"
                      id="time-filter-button"
                    >
                      Time
                    </button>
                  </div>
                </th>
                <th
                  v-for="(day, dayIndex) in days"
                  :key="dayIndex"
                  class="m-0 p-0"
                  style="position: relative; min-width: 200px;"
                >
                  <button
                    style="color: white; border: none; padding: 5px; cursor: pointer; width:100%; height: 50px; background-color:transparent"
                    :id="day + '-filter-button'"
                  >
                    {{ day.charAt(0).toUpperCase() + day.slice(1) }}
                    <br /><small>{{ weeksDates[weekIndex][dayIndex] }}</small>
                  </button>
                </th>
              </tr>
            </thead>

            <tbody class="text-center">
              <template v-if="filterredScheduleDatas[week]">
                <!-- AM Row -->
                <tr>
                  <td>AM</td>
                  <td v-for="day in days" :key="'am' + day">
                    <template
                      v-if="
                        filterredScheduleDatas[week].am[day] &&
                          filterredScheduleDatas[week].am[day].length > 0
                      "
                    >
                      <div
                        v-for="(task, taskIndex) in filterredScheduleDatas[week]
                          .am[day]"
                        :key="'am' + day + taskIndex"
                      >
                        <popover
                        :is-self-change="true"
                          @changeTaskCompleted="childSelectWeek"
                          :day="task"
                          @formShow="handleFormShow"
                          :day-index="taskIndex"
                          :user-role="userRole"
                          :department-id="departmentId"
                          :tasks="tasks"
                          :targetId="task.id.toString()"
                          v-model="
                            filterredScheduleDatas[week].am[day][taskIndex]
                          "
                          class="mb-25 mt-25"
                        >
                          <div
                            :style="{
                              backgroundColor: task.task.color.bgColor,
                              color: task.task.color.textColor,
                            }"
                          >
                            {{ task.task.title }}
                          </div>
                        </popover>
                        <b-badge pill class="cursor-pointer" :id="'site-' + task.id" :style="{
    backgroundColor: hoveredTaskId === task.id && task.site_change_awaits 
      ? task.new_color 
      : task.color
  }">
          <span v-if="!task.site_change_awaits">
            {{ task.site }}
          </span>
          <span v-else  :class="{ 'overline': !hoveredTaskId || hoveredTaskId !== task.id }"
              @mouseover="hoveredTaskId = task.id" 
              @mouseleave="hoveredTaskId = null">
          
              {{ hoveredTaskId === task.id ? task.new_site : task.site }}
          </span>
     
      </b-badge>

    <!-- The popover -->
    <b-popover v-if="availableSites.length>0 && isHomepage" :target="'site-' + task.id" triggers="click blur">
      <b-list-group>
        <b-list-group-item class="cursor-pointer" v-for="(site,index) in availableSites" @click="updateRotaLocationUser(site,filterredScheduleDatas[week].am[day][taskIndex])" :key="index">
          {{ site }}
        </b-list-group-item>
      </b-list-group>
    </b-popover>

                      </div>
                    </template>
                    <template v-else>--</template>
                  </td>
                </tr>

                <!-- PM Row -->
                <tr>
  <td>PM</td>
  <td v-for="day in days" :key="'pm' + day">
    <template
      v-if="
        filterredScheduleDatas[week].pm[day] &&
          filterredScheduleDatas[week].pm[day].length > 0
      "
    >
      <div
        v-for="(task, taskIndex) in filterredScheduleDatas[week].pm[day]"
        :key="'pm' + day + taskIndex"
      >
        <popover
          :is-self-change="true"
          @changeTaskCompleted="childSelectWeek"
          :day="task"
          @formShow="handleFormShow"
          :day-index="taskIndex"
          :user-role="userRole"
          :department-id="departmentId"
          :tasks="tasks"
          :targetId="task.id.toString()"
          v-model="
            filterredScheduleDatas[week].pm[day][taskIndex]
          "
          class="mb-25 mt-25"
        >
          <div
            :style="{
              backgroundColor: task.task.color.bgColor,
              color: task.task.color.textColor,
            }"
          >
            {{ task.task.title }}
          </div>
        </popover>

        <b-badge pill class="cursor-pointer" :id="'site-' + task.id" :style="{
    backgroundColor: hoveredTaskId === task.id && task.site_change_awaits 
      ? task.new_color 
      : task.color
  }">
          <span v-if="!task.site_change_awaits">
            {{ task.site }}
          </span>
          <span v-else  :class="{ 'overline': !hoveredTaskId || hoveredTaskId !== task.id }"
              @mouseover="hoveredTaskId = task.id" 
              @mouseleave="hoveredTaskId = null">
          
              {{ hoveredTaskId === task.id ? task.new_site : task.site }}
          </span>
     
      </b-badge>

    <!-- The popover -->
    <b-popover v-if="availableSites.length>0 && isHomepage" :target="'site-' + task.id" triggers="click blur">
      <b-list-group>
        <b-list-group-item class="cursor-pointer" v-for="(site,index) in availableSites" @click="updateRotaLocationUser(site,filterredScheduleDatas[week].pm[day][taskIndex])" :key="index">
          {{ site }}
        </b-list-group-item>
      </b-list-group>
    </b-popover>
      </div>
    </template>
    <template v-else>--</template>
  </td>
</tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </span>

    <b-modal
      id="duplicate-project"
      size="xl"
      title="Weeks to be Updated"
      centered
      ok-title="Duplicate Week"
      no-close-on-esc
      no-close-on-backdrop
      ok-only
      @ok="duplicateWeek"
      no-stacking
      @close="closeDuplicateModal()"
    >
      <b-row class="bg-light-secondary mb-1">
        <b-col
          style="padding:1%;"
          class="d-flex justify-content-start align-items-center"
        >
          <span>Copy source:</span>
          <treeselect
            :multiple="true"
            :options="dropdownWeeks"
            placeholder="Select Source Week(s)"
            class="p-1 source-copy-weeks"
            v-model="selectedSourceCopyWeeks"
            @input="selectSourceCopyWeeks(selectedSourceCopyWeeks)"
            :clearable="false"
            :limit="3"
            :limitText="(count) => `and ${count} week(s)`"
            :open-on-click="true"
            :searchable="true"
            style="width: 100%;max-width: 400px;"
          >
            <div slot="value-label" slot-scope="{ node }">
              {{ node.raw.customLabel }}
            </div>
          </treeselect>

          <!-- <b-button variant="primary" class="ml-2 p-1 m-0"><strong>Week: </strong> {{ selectedWeek }} - <strong>Year: </strong> {{ selectedYear }} </b-button> -->
        </b-col>

        <b-col
          style="padding:1%;"
          class="d-flex justify-content-start align-items-center"
        >
          <b-form-checkbox v-model="amChecked">AM</b-form-checkbox>
          <b-form-checkbox v-model="pmChecked" class="ml-2">PM</b-form-checkbox>
        </b-col>

        <b-col
          style="padding:1%;"
          class="d-flex justify-content-start align-items-center"
        >
          <b-form-checkbox v-model="daysChecked.monday">Mon</b-form-checkbox>
          <b-form-checkbox v-model="daysChecked.tuesday" class="ml-2"
            >Tue</b-form-checkbox
          >
          <b-form-checkbox v-model="daysChecked.wednesday" class="ml-2"
            >Wed</b-form-checkbox
          >
          <b-form-checkbox v-model="daysChecked.thursday" class="ml-2"
            >Thu</b-form-checkbox
          >
          <b-form-checkbox v-model="daysChecked.friday" class="ml-2"
            >Fri</b-form-checkbox
          >
        </b-col>

        <b-col class="d-flex justify-content-end align-items-center">
          <span>Target year</span>
          <b-dropdown
            v-model="selectedYearDuplicate"
            variant="secondary"
            class="ml-1"
          >
            <template #button-content>
              {{ selectedYearDuplicate }}
            </template>
            <b-dropdown-item
              v-for="year in availableYears"
              :key="year.id"
              @click="selectDuplicateYear(year)"
            >
              {{ year.label }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="d-flex flex-wrap justify-content-center">
            <b-button
              :variant="
                duplicateWeeks.includes(week.week_number)
                  ? 'success'
                  : 'secondary'
              "
              class="mr-25 p-1 m-25"
              v-for="week in availableDuplicateWeeks"
              :key="week.week_number"
              @click="addDuplicateWeek(week.week_number)"
            >
              <span v-b-tooltip.hover :title="formattedDate(week.start_date)">
                {{ week.week_number }}</span
              >
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2 bg-light-secondary p-1">
        <b-col cols="12" class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('all')"
            class="mr-2 btn btn"
          >
            Select All
          </b-button>
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('past')"
            class="mr-2 btn btn"
          >
            Select Past
          </b-button>
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('future')"
            class="mr-2 btn btn"
          >
            Select Future
          </b-button>
          <b-button
            variant="outline-primary"
            @click="multipleDuplicate('unselect')"
            class="mr-2 btn btn"
          >
            Clear Selection
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12">
          <p
            v-if="
              amChecked ||
                pmChecked ||
                Object.values(daysChecked).includes(true)
            "
          >
            {{ getSelectedOptionsMessage }}
          </p>
          <p v-else>
            All records from Week {{ selectedSourceCopyWeeks }} of [{{
              selectedYear
            }}] will be copied to the selected weeks
            {{
              duplicateWeeks.length > 0 ? duplicateWeeks : "-No Select Week-"
            }}
            of [{{ selectedYearDuplicate }}].
          </p>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>

  <div v-else class="text-danger text-center mt-5">No Permission</div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BModal,
  VBPopover,
} from "bootstrap-vue";
import moment from "moment";
import toolStoreModule from "../../Tool/toolStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Popover from "../../Tool/Popover.vue";
import router from "@/router";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import flatPickr from "vue-flatpickr-component";
import AppColorPaletteVue from "@/@core/layouts/components/AppColorPalette.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    ToastificationContent,
    Popover,
    Treeselect,
    flatPickr,
    AppColorPaletteVue,
  },

  directives: {
    "b-popover": VBPopover,
  },

  setup() {
    const TOOl_APP_STORE_MODULE_NAME = "dashboard";
    // Register module
    if (!store.hasModule(TOOl_APP_STORE_MODULE_NAME))
      store.registerModule(TOOl_APP_STORE_MODULE_NAME, toolStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TOOl_APP_STORE_MODULE_NAME))
        store.unregisterModule(TOOl_APP_STORE_MODULE_NAME);
    });
  },

  props: {
    userId: {
      type: Number,
      default: false,
      required:true
    },
    isHomepage: {
      type: Boolean,
      default: false,
    },
    availableSites: {
    type: Array,
    default: () => [],
    required: false
  }
   
  },

  data() {
    const now = moment(new Date());

    return {
      days: ["monday", "tuesday", "wednesday", "thursday", "friday"],
      selectedYearDuplicate: new Date().getFullYear(),
      selectedMonth: now.month() + 1,
      dateNow: now.format("DD/MM/YYYY"),
      userData: JSON.parse(localStorage.getItem("userData")),
      availableDuplicateWeeks: [],
      selectedWeek: now.isoWeek(),
      selectedWeeks: [],
      duplicateWeeks: [],
      selectedSourceCopyWeeks: [],
      formShow: false,
      weeks: [],
      dropdownWeeks: [],
      scheduleData: [],
      filterredScheduleDatas: [],
      weeksDates: [],
      tasks: [],
      userRole: null,
      departmentId: null,

      departmentsData: [],
      sitesData: [],
      staffsData: [],
      dayTasksData: [],

      amChecked: true,
      pmChecked: true,

      daysChecked: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
      },

      isStart:true,
      hoveredTaskId: null, // Initialize with no task being hovered over

      selectedYear: new Date().getFullYear(),
      availableYears: [
        { id: 2024, label: 2024 },
        { id: 2025, label: 2025 },
        { id: 2026, label: 2026 },
        { id: 2027, label: 2027 },
        { id: 2028, label: 2028 },
        { id: 2029, label: 2029 },
        { id: 2030, label: 2030 },
      ],
      availableMonths: [
        { id: 1, label: "January" },
        { id: 2, label: "February" },
        { id: 3, label: "March" },
        { id: 4, label: "April" },
        { id: 5, label: "May" },
        { id: 6, label: "June" },
        { id: 7, label: "July" },
        { id: 8, label: "August" },
        { id: 9, label: "September" },
        { id: 10, label: "October" },
        { id: 11, label: "November" },
        { id: 12, label: "December" },
      ],
      windowHeight: window.innerHeight - 350,

      config: {
        dateFormat: "d-m-Y",
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.selectMonth(moment(new Date()).month() + 1);
      window.addEventListener("resize", this.getWindowHeight(350));
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight(350));
  },

  computed: {
    getSelectedOptionsMessage() {
      let message = `Records from Week ${
        this.selectedSourceCopyWeeks.length > 0
          ? this.selectedSourceCopyWeeks
          : "-No selected source week-"
      } of [${this.selectedYear}] will be copied to the selected weeks [${
        this.duplicateWeeks.length > 0
          ? this.duplicateWeeks
          : "-No Selected target Week-"
      }] of [${this.selectedYearDuplicate}]`;

      if (this.amChecked && this.pmChecked) {
        message += " for [AM,PM]";
      } else {
        if (this.amChecked) message += " for [AM]";
        if (this.pmChecked) message += " for [PM]";
      }

      const days = [];
      if (this.daysChecked.monday) days.push("Monday");
      if (this.daysChecked.tuesday) days.push("Tuesday");
      if (this.daysChecked.wednesday) days.push("Wednesday");
      if (this.daysChecked.thursday) days.push("Thursday");
      if (this.daysChecked.friday) days.push("Friday");

      if (days.length > 0) {
        message += " on [" + days.join(", ") + "]";
      }

      return message + ".";
    },

    displayedWeeks() {
      const selectedWeekIndex = this.weeks.findIndex(
        (week) => week.week_number === this.selectedWeek
      );
      const start = Math.max(0, selectedWeekIndex - 2);
      const end = Math.min(this.weeks.length, start + 5);
      return this.weeks.slice(start, end);
    },

    getMonthDate() {
      const month = this.availableMonths.find(
        (month) => month.id === this.selectedMonth
      );
      return month ? month.label : "";
    },
  },

  methods: {

    updateRotaLocationUser(site,job){
     
      store.dispatch("dashboard/updateRotaLocationUser", {'site':site,'job':job}).then((response) => {
            if (response.status == 200) {
              this.childSelectWeek();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successful",
                  text: "✔️ User Update Successful",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
            }
          });
    },



    getRowspan(scheduleData) {
      const hasAM = this.hasItems(scheduleData, "am");
      const hasPM = this.hasItems(scheduleData, "pm");
      return hasAM && hasPM ? 2 : 1;
    },

    hasItems(scheduleData, time) {
      const days = ["monday", "tuesday", "wednesday", "thursday", "friday"];
      return days.some((day) => scheduleData[time][day].length > 0);
    },

    handleFormShow(value) {
      this.formShow = value;
    },

    getWindowHeight(val) {
      this.windowHeight = window.innerHeight - val;
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async fetchWeeks() {
      this.formShow = true;
      try {
        const res = await store.dispatch("dashboard/fetchWeeks", {
          year: this.selectedYear,
          month: this.selectedMonth,
        });
        this.processFetchedWeeks(res.data);
      } catch (error) {
        this.handleFetchError(error, "fetchWeeks");
      }
    },

    processFetchedWeeks(data) {
      this.weeks = data.weeks;
      this.dropdownWeeks = this.weeks.map((week) => ({
        id: week.week_number,
        label: `Week ${week.week_number}  | ${this.formattedDate(
          week.start_date
        )} - ${this.formattedDate(week.end_date)}`,
        customLabel: `Week ${week.week_number} `,
      }));

      this.departmentsData = data.departments || [];
      this.sitesData = data.sites || [];
      this.staffsData = data.staffs || [];
      this.dayTasksData = data.tasks || [];
      this.tasks = data.tasks;
      this.availableDuplicateWeeks = data.weeks;
      this.selectWeeks(this.selectedWeeks);
    },

    selectYear(year) {
      this.selectedWeek = 1;
      this.selectedYear = year;
      this.selectedYearDuplicate = year;
      this.fetchWeeks();
    },

    selectMonth(month) {
      this.selectedMonth = month;
      if(this.isStart){
        this.selectedWeeks = this.calculateCurrentAndNextThreeWeeks();
        this.isStart = false;

      }
      else{
        this.selectedWeeks = this.calculateWeeksInMonth(this.selectedYear, month);
      }
      this.fetchWeeks();
    },

    calculateWeeksInMonth(year, month) {
      const firstDay = moment(`${year}-${month}-01`);
      const lastDay = firstDay.clone().endOf("month");
      const weeks = [];

      let currentWeek = firstDay.isoWeek();
      weeks.push(currentWeek);

      while (firstDay.add(1, "week").isoWeek() !== currentWeek) {
        currentWeek = firstDay.isoWeek();
        if (firstDay.isAfter(lastDay)) break;
        weeks.push(currentWeek);
      }

      return weeks;
    },


    calculateCurrentAndNextThreeWeeks() {
      const currentWeek = moment().isoWeek();
      const year = moment().year();
      const weeks = [];

      for (let i = 0; i < 4; i++) {
    
        weeks.push(currentWeek + i);
      }

      return weeks;
    },


    async selectWeeks(val) {
      if (this.weeks.length > 0) {
        this.selectedWeeks = val.sort((a, b) => a - b); // Haftaları sıralı hale getiriyoruz
        this.weeksDates = this.calculateWeekDates(this.selectedWeeks);

        this.formShow = true;
        try {
          const res = await store.dispatch("dashboard/getScheduleByWeeks", {
            year: this.selectedYear,
            weeks: this.selectedWeeks,
            userId: parseInt(this.userId),
          });
          this.scheduleData = res.data;
          this.filterredScheduleDatas = res.data;
          this.formShow = false;
        } catch (error) {
          this.handleFetchError(error, "getScheduleByWeeks");
        }
      }
    },

    calculateWeekDates(weeks) {
      return weeks.map((weekNumber) => {
        const startDate = moment()
          .year(this.selectedYear)
          .week(weekNumber)
          .startOf("week");
        return Array.from({ length: 5 }, (_, i) =>
          startDate
            .clone()
            .add(i + 1, "days")
            .format("DD/MM/YYYY")
        );
      });
    },

    childSelectWeek() {
      this.formShow = true;
      this.selectWeeks(this.selectedWeeks);
    },

    async selectDuplicateYear(year) {
      this.duplicateWeeks = [];
      this.selectedYearDuplicate = year.label;
      if (this.selectedYearDuplicate !== new Date().getFullYear()) {
        try {
          const res = await store.dispatch("dashboard/fetchWeeks", {
            year: this.selectedYearDuplicate,
          });
          this.availableDuplicateWeeks = res.data.weeks;
        } catch (error) {
          this.handleFetchError(error, "fetchWeeks");
        }
      }
    },

    duplicate() {
      this.$nextTick(() => {
        this.$bvModal.show("duplicate-project");
      });
    },

    async downloadUserPDF() {
      this.formShow = true;
      try {
        const response = await store.dispatch("dashboard/downloadUserPDF", {
          year: this.selectedYear,
          weeks: this.selectedWeeks,
          userId: parseInt(this.userId),
        });

        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "YYYY-XX-Week-Schedule.pdf");
          document.body.appendChild(link);
          link.click();
          this.showToast(
            "Successful",
            "✔️ Report is created!",
            "ThumbsUpIcon",
            "success"
          );
        }
        this.formShow = false;
      } catch (error) {
        this.handleFetchError(error, "downloadUserPDF");
      }
    },

    addDuplicateWeek(week_number) {
      const index = this.duplicateWeeks.indexOf(week_number);
      if (index > -1) {
        this.duplicateWeeks.splice(index, 1);
      } else {
        this.duplicateWeeks.push(week_number);
      }
      this.checkExistingWeeks();
    },

    async duplicateWeek() {
      if (this.duplicateWeeks.length > 0) {
        try {
          const confirmed = await this.$bvModal.msgBoxConfirm(
            "Are you sure you want to update the selected weeks?",
            {
              size: "sm",
              title: "Confirm",
              okVariant: "primary",
              okTitle: "Yes",
              cancelTitle: "No",
              cancelVariant: "outline-secondary",
              hideHeaderClose: true,
              centered: true,
            }
          );

          if (confirmed) {
            await this.duplicateWeeksAction();
            this.showToast(
              "Successful",
              "Selected weeks have been successfully updated.",
              "success"
            );
          } else {
            this.cancelDuplicateProcess();
          }
        } catch (error) {
          this.handleDuplicateError(error);
        }
      } else {
        this.showToast(
          "No Weeks Selected",
          "You haven't selected any weeks to be updated.",
          "warning"
        );
      }
    },

    async duplicateWeeksAction() {
      // if (this.duplicateWeeks.includes(this.selectedWeek) && this.selectedYearDuplicate == this.selectedYear) {
      //   this.duplicateWeeks.splice(this.duplicateWeeks.indexOf(this.selectedWeek), 1);
      // }

      await store.dispatch("dashboard/multipleDuplicateWeeks", {
        duplicateWeeks: this.duplicateWeeks,
        selectedSourceCopyWeeks: this.selectedSourceCopyWeeks ?? [],
        currentWeek: this.selectedWeek,
        amChecked: this.amChecked,
        pmChecked: this.pmChecked,
        daysChecked: this.daysChecked,
        year: this.selectedYear,
        selectedYearDuplicate: this.selectedYearDuplicate,
        selectedUserId: parseInt(this.userId),
      });

      this.duplicateWeeks = [];
      this.selectedYearDuplicate = this.selectedYear;
      this.fetchWeeks();
      this.closeDuplicateWeekModal();
    },

    closeDuplicateWeekModal() {
      this.$bvModal.hide("duplicate-project");
      this.duplicateWeeks = [];
      this.selectedSourceCopyWeeks = [];
      this.selectedYearDuplicate = this.selectedYear;
      this.amChecked = true;
      this.pmChecked = true;
      this.daysChecked = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
      };
    },

    selectSourceCopyWeeks(week_number) {
      this.checkExistingWeeks();
    },

    checkExistingWeeks() {
      if (this.selectedYearDuplicate == this.selectedYear) {
        this.selectedSourceCopyWeeks.forEach((week) => {
          const index = this.duplicateWeeks.indexOf(week);
          if (index !== -1) {
            this.duplicateWeeks.splice(index, 1);
          }
        });
      }
    },

    multipleDuplicate(option) {
      this.duplicateWeeks = [];
      const currentYear = new Date().getFullYear();
      switch (option) {
        case "all":
          this.duplicateWeeks = this.availableDuplicateWeeks.map(
            (e) => e.week_number
          );
          break;
        case "past":
          this.handlePastWeeksSelection(currentYear);
          break;
        case "future":
          this.handleFutureWeeksSelection(currentYear);
          break;
        case "unselect":
          this.duplicateWeeks = [];
          break;
        default:
          break;
      }
    },

    handlePastWeeksSelection(currentYear) {
      if (this.selectedYearDuplicate < currentYear) {
        this.duplicateWeeks = this.availableDuplicateWeeks.map(
          (e) => e.week_number
        );
      } else if (this.selectedYearDuplicate > currentYear) {
        this.duplicateWeeks = [];
      } else {
        this.duplicateWeeks = this.availableDuplicateWeeks
          .filter((e) => e.week_number < this.selectedWeek)
          .map((e) => e.week_number);
      }
    },

    handleFutureWeeksSelection(currentYear) {
      if (this.selectedYearDuplicate > currentYear) {
        this.duplicateWeeks = this.availableDuplicateWeeks.map(
          (e) => e.week_number
        );
      } else if (this.selectedYearDuplicate < currentYear) {
        this.duplicateWeeks = [];
      } else {
        this.duplicateWeeks = this.availableDuplicateWeeks
          .filter((e) => e.week_number > this.selectedWeek)
          .map((e) => e.week_number);
      }
    },

    formattedDate(startDate) {
      return moment(startDate).format("DD/MM/YYYY");
    },

    selectedDepartmentsChange() {},

    showToast(title, text, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant,
        },
      });
    },

    handleFetchError(error, action) {
      console.error(`Error during ${action}:`, error);
      this.formShow = false;
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "An error occurred",
          text: `Please try again later or contact support. Error: ${error.message}`,
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },

    handleDuplicateError(error) {
      console.error("duplicate error: ", error);
      this.showToast(
        "Error",
        "Duplicate process could not be completed. Please try again or contact support.",
        "error"
      );
      this.formShow = false;
    },

    cancelDuplicateProcess() {
      this.duplicateWeeks = [];
      this.selectedYearDuplicate = this.selectedYear;
      this.showToast(
        "Process cancelled",
        "Duplicate process has been cancelled.",
        "warning"
      );
    },

    getTimeLabel(time) {
      return time === "AM" ? "AM" : "PM";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.weekTableCard {
  background-color: white;
  padding: 0px !important;
  overflow-x: auto;
}

.weekTableCard::-webkit-scrollbar {
  width: 10px;
}

.weekTableCard::-webkit-scrollbar-track {
  background: #e1f5fe;
}

.weekTableCard::-webkit-scrollbar-thumb {
  background: #888;
}

.weekTableCard::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.overline {
  text-decoration:line-through;
}
</style>
