import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get("settings", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTimeline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get("timeline")
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    ///TASK

    saveProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("saveProjectTask", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateProjectTask", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskDueDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskDueDate", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskAssigneUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskAssigneUser", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskPriority(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskPriority", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("updateTaskIsCompleted", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsersProject(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("projectUserList", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPermissionListReqChange(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getPermissionListReqChange", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getScheduleReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get("getScheduleReport", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchWeeks(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("fetchWeeks", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getScheduleByWeek(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getScheduleByWeek", { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    approveScheduleItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("approveScheduleItem", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    rejectScheduleItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("rejectScheduleItem", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    passwordReset(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("passwordReset", user)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
